<template>
    <b-container v-if="edtPenalties.version==1">
        <PenaltyRow class="even" v-model="edtPenalties.depassementTpsPrepa" :readonly="readonly" @change="emit_change">
            {{ $t("scoring-penalties.overhead_preparation_time")}}
        </PenaltyRow>
        <PenaltyRow class="odd" v-model="edtPenalties.absenceDebutOuFin" :readonly="readonly" @change="emit_change">
            {{ $t("scoring-penalties.missing_start_or_end")}}
        </PenaltyRow>
        <PenaltyRow class="even" v-model="edtPenalties.mauvaiseFigure" :readonly="readonly" @change="emit_change">
            {{ $t("scoring-penalties.wrong_compulsory")}}
        </PenaltyRow>
        <PenaltyRow class="odd" v-model="edtPenalties.contacts" :readonly="readonly" @change="emit_change">
            {{ $t("scoring-penalties.possible_hits") }}
        </PenaltyRow>
        <PenaltyRow class="even" v-model="edtPenalties.crash" :readonly="readonly" @change="emit_change">
            {{ $t("scoring-penalties.crash") }} <info-helper>{{$t("scoring-penalties.crash-info-helper") }}</info-helper>
        </PenaltyRow>
        <PenaltyRow class="odd caution-border" v-model="edtPenalties.sortieTerrain" :readonly="readonly" @change="emit_change">
            {{ $t("scoring-penalties.fly_across_limits")}}<br/>(<b style="color:red;">{{$t("staff-roles.field-director.abbreviate")}}</b>)<info-helper>{{$t("scoring-penalties.fly_accross_limits-info-helper")}}</info-helper>
        </PenaltyRow>        
    </b-container>
</template>

<script>
import InfoHelper from '@/components/InfoHelper.vue'
import PenaltyRow from './PenaltyDetailHelperRow.vue';

export default{
  components: { InfoHelper, PenaltyRow },
    props:{
        value:{ type:Object, required:true},
        readonly:{ type:Boolean, default: false},
    },
    data(){
        return {
            edtPenalties: this.value,
        }
    },
    methods:{
        emit_change(){
            this.$emit('change');
        }
    }
}
</script>

<style scoped>
    .caution-border{ border: solid 2px red; }
</style>
